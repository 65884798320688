const logout = ({ next }) => {
  localStorage.removeItem('token')
  localStorage.removeItem('withEndCustomers')
  localStorage.removeItem('currentCompanySlug')
  sessionStorage.removeItem('userRequestUrl')
  window.$chatwoot?.reset()
  return next({ name: 'login' })
}

export default logout
