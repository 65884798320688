<template>
  <div class="app">
    <router-view />
    <VNewVersion
      v-model:is-modal-show="isModalShow"
      @cancel="isModalShow = false"
      @submit="handleModalSubmit()"
    />
  </div>
</template>

<script lang="ts" setup>
import { provide, ref, readonly, onMounted, onUnmounted, computed } from "vue"
import { useResizeObserver } from "@vueuse/core"
import { VNewVersion } from "@vsimple/vsimple-ui"
import Pusher from "pusher-js"

const width = ref<number>(window.innerWidth)
useResizeObserver(document.body, (entries) => {
  window.requestAnimationFrame(() => {
    if (!Array.isArray(entries) || !entries.length) {
      return
    }

    const entry = entries[0]
    const { width: contentRectWidth, height } = entry.contentRect
    width.value = contentRectWidth
    document.documentElement.style.setProperty('--vh', `${height * 0.01}px`)
    document.documentElement.style.setProperty('--100vh', `${height}px`)
  })
})

const mobileDetect = computed(() => width.value < 768)
const smallLaptopDetect = computed(() => width.value <= 1440)
const tabletDetect = computed(() => width.value < 1024)

provide('mobileDetect', readonly(mobileDetect))
provide('smallLaptopDetect', readonly(smallLaptopDetect))
provide('tabletDetect', readonly(tabletDetect))

const interval = ref(null)

const checkNewVersionAvailable = () => {
  const req = new Request('/build-info.json', {
    method: 'GET',
    cache: 'no-store',
  })

  fetch(req)
    .then((data) => data.json())
    .then((data) => {
      if (data.buildHash !== import.meta.env.VITE_APP_RELEASE_HASH) {
        isModalShow.value = true
      }
    })
}

const setCheckNewVersionInterval = () => {
  if (interval.value) {
    clearInterval(interval.value)
  }

  interval.value = setInterval(checkNewVersionAvailable, 5 * 60 * 1000)
}

onMounted(() => {
  if (import.meta.env.VITE_APP_ENV !== 'development') {
    checkNewVersionAvailable()
    setCheckNewVersionInterval()
  }
})
const pusher = new Pusher(
  import.meta.env.VITE_APP_RELEASE_NOTIFIER_PUSHER_APP_KEY,
  {
    cluster: import.meta.env.VITE_APP_RELEASE_NOTIFIER_PUSHER_APP_CLUSTER,
    enabledTransports: ['ws'],
    forceTLS: true,
  },
)

provide('pusher', pusher)

if (import.meta.env.VITE_APP_ENV !== 'development') {
  pusher.subscribe(import.meta.env.VITE_APP_RELEASE_NOTIFIER_PUSHER_CHANNEL)

  pusher.bind(import.meta.env.VITE_APP_RELEASE_NOTIFIER_PUSHER_VSIMPLE_FRONTAPP_RELEASE_EVENT, () => {
    isModalShow.value = true
    setCheckNewVersionInterval()
  })
}

onUnmounted(() => {
  clearInterval(interval.value)
})

const isModalShow = ref<boolean>(false)
const handleModalSubmit = () => {
  window.location.reload()
}
</script>

<style lang="scss">
@import "@/scss/main.scss";
</style>
