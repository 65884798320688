import axios from 'axios'
import cloneDeep from 'lodash.clonedeep'
import { records, user } from '@/api'
import formatFilters from '@/utils/formatFilters'
import CUSTOM_FIELDS_TYPES from '@/constants/enums/CUSTOM_FIELDS_TYPES'
import userResponseMapper from '@/js/mappers/usersResponseMapper'
import { loadNotificationByRecords, markNotificationsRead } from "@/api/notifications"
import eventBus from "@/eventBus"

export default {
  getRecords: function ({commit, dispatch, rootState}, filters) {
    commit('preloader', true)
    commit('errorUpdateRecord', {})
    const viewFiltersId = cloneDeep(filters.filterViewId)

    return records.getRecords({
      ...formatFilters.toObject(filters),
      persistFilters: filters.persistFilters,
    }, true)
      .then(({ data }) => {
        commit('setViewFiltersId', viewFiltersId)
        if (data.data.length && rootState?.user?.config?.notification_widget?.display) {
          loadNotificationByRecords(data.data.map((record) => record.id).join('|'))
            .then((notifications) => {
              commit('setRecords', data.data.map((record) => ({
                ...record,
                notificationsCount: notifications[record.id],
              })))
          }).catch(() => {
            commit('setRecords', data.data)
          }).finally(() => {
            dispatch('getRecordsUsers')
            commit('setViewFiltersId', data.meta.filterViewId || -1)
            commit('setPagination', data.meta)
            commit('preloader', false)
          })
        } else {
          commit('setRecords', data.data)
          dispatch('getRecordsUsers')
          commit('setViewFiltersId', data.meta.filterViewId || -1)
          commit('setPagination', data.meta)
          commit('preloader', false)
        }
      })
      .catch((e) => {
        if (!axios.isCancel(e)) {
          console.error(e)
        }
      })
  },

  getMoreRecords: function ({commit, state, dispatch, rootState}, filters) {
    commit('preloaderMore', true)
    const viewFiltersId = cloneDeep(filters.filterViewId)
    return records.getRecords({
      ...formatFilters.toObject(filters),
      persistFilters: filters.persistFilters,
    })
      .then(({ data }) => {
        commit('setViewFiltersId', viewFiltersId)
        if (data.data.length && rootState?.user?.config?.notification_widget?.display) {
          loadNotificationByRecords(data.data.map((record) => record.id).join('|')).then((notifications) => {
            commit('setRecords', [...state.records, ...data.data.map((record) => ({
              ...record,
              notificationsCount: notifications[record.id],
            }))])
          }).catch(() => {
            commit('setRecords', [...state.records, ...data.data])
          }).finally(() => {
            dispatch('getRecordsUsers')
            commit('setViewFiltersId', data.meta.filterViewId || -1)
            commit('preloaderMore', false)
          })
        } else {
          commit('setRecords', [...state.records, ...data.data])
          dispatch('getRecordsUsers')
          commit('setViewFiltersId', data.meta.filterViewId || -1)
          commit('preloaderMore', false)
        }
      })
      .catch((e) => {
        if (!axios.isCancel(e)) {
          console.error(e)
        }
      })
  },

  getRecordsUsers({ getters, commit }) {
    const userIds = getters.getFormattedRecords
      .reduce((prev, current) => ([
        ...current.customFields,
        ...prev,
      ]), [])
      .filter((field) => field.type === CUSTOM_FIELDS_TYPES.user && field.response)
      .map((field) => field.response)
    const lastModifiedIds = getters.getFormattedRecords
      .filter((record) => record.lastModifiedBy)
      .map((record) => String(record.lastModifiedBy))
    const uniqueUserIds = [...new Set([...userIds, ...lastModifiedIds])]
    if (uniqueUserIds.length) {
      user.getUsers({ company: localStorage.getItem('supplier_company'), params: {
        userIds: uniqueUserIds.join('|'),
        per_page: uniqueUserIds.length <= 10
          ? 10
          : uniqueUserIds.length,
        fields: 'user[id,firstName,lastName]',
        with_hidden: true,
      }}).then(({ data: { data: usersResponse } }) => {
        commit('setRecordsUsers', userResponseMapper(usersResponse))
      })
    }
  },
  getRecordUsers({ getters, commit }) {
    const userIds = getters.getFormattedRecord.customFields
      .filter((field) => field.type === CUSTOM_FIELDS_TYPES.user && field.response)
      .map((field) => field.response)
    const lastModifiedIds = String(getters.getFormattedRecord?.lastModifiedBy)
    const uniqueUserIds = [...new Set([...userIds, lastModifiedIds])]
    if (uniqueUserIds.length) {
      user.getUsers({ company: localStorage.getItem('supplier_company'), params: {
        userIds: [...uniqueUserIds].join('|'),
        per_page: uniqueUserIds.length <= 10
          ? 10
          : uniqueUserIds.length,
        fields: 'user[id,firstName,lastName]',
        with_hidden: true,
      }}).then(({ data: { data: usersResponse } }) => {
        commit('setRecordUsers', userResponseMapper(usersResponse))
      })
    }
  },
  updateRecordsRedDots({ state, commit }) {
    const ids = state.records.map((record) => record.id)
    if (ids.length) {
      commit('setRecords', state.records)
      return loadNotificationByRecords(ids.join('|')).then((notifications) => {
        commit('setRecords', state.records.map((record) => ({
          ...record,
          notificationsCount: notifications[record.id],
        })))
      })
    }
    return null
  },
  exportRecords({ commit }, payload) {
    commit('preloaderExport', true)
    return records.exportRecords(payload)
      .finally((res) => {
        commit('preloaderExport', false)
        return res
      })
  },
  editRecord({ commit, dispatch }, payload) {
    commit('preloaderSaving', true)
    return records.editRecord({ id: payload.id, payload: { ...payload.payload, fields: 'quote[id,author[avatar,id,currentSupplier,firstName,lastName],collaborators[firstName,lastName,email,id,avatar],createdDate,description,dueDate,name,status,statusLastChangedAt,updatedDate,recordTypeId,customFields[hasHistory,slug,id,quoteId,response],lastModifiedBy,meta]' } })
      .then((data) => {
        commit('updateRecordItem', { isSingle: payload.isSingle, data: data.data })
        commit('clearUpdateRecordErrorById', payload.id)
        dispatch('getRecordUsers')
        return data
      }).catch((error) => {
        commit('errorUpdateRecord', { id: payload.id, error })
        commit('preloader', false)
        throw error
      })
      .finally(() => {
        commit('preloaderSaving', false)
        commit('preloader', false)
      })
  },
  editRecords({ commit, dispatch }, {
    id, payload, ignoreEventBus = false
  }) {
    commit('preloaderSaving', true)
    return records.editRecord({ id, payload }, undefined, ignoreEventBus)
      .then(({ data }) => {
        commit('updateRecordItem', { id, data: data.data, isSingle: false })
        if ('isLast' in payload && !payload.isLast) {
          return data
        }

        dispatch('getRecordsUsers')
        return data
      }).catch((error) => {
        commit('errorUpdateRecord', { id, error })
        commit('preloader', false)
        commit('preloaderSaving', false)
        throw error
      }).finally(() => {
        commit('preloader', false)
        commit('preloaderSaving', false)
      })
  },
  createRecord({ commit }, payload) {
    commit('preloader', true)
    return records.createRecord(payload)
      .then((data) => data)
      .catch((err) => {
        commit('preloader', false)
        throw err
      }).finally(() => {
        commit('preloader', false)
      })
  },
  getRecord({ commit, dispatch }, id) {
    commit('preloader', true)
    commit('errorUpdateRecord', {})
    return records.getRecord(id, { fields: 'quote[id,author[avatar,id,currentSupplier,firstName,lastName],createdDate,description,dueDate,name,status,statusLastChangedAt,updatedDate,recordTypeId,customFields[slug,id,response,hasHistory],lastModifiedBy,meta]' })
      .then(({ data }) => {
        commit('setRecordMeta', data.meta)
        commit('setRecord', data.data)
        dispatch('getRecordUsers')
      })
      .finally(() => {
        markNotificationsRead(id, [2]).then(() => {
          eventBus.$emit('activityStreamLoad')
        })
        commit('preloader', false)
      })
  },
  getRecordSilent({ commit, dispatch }, id) {
    commit('errorUpdateRecord', {})
    return records.getRecord(id, { fields: 'quote[id,author[avatar,id,currentSupplier,firstName,lastName],createdDate,description,dueDate,name,status,statusLastChangedAt,updatedDate,recordTypeId,customFields[slug,id,response,hasHistory],lastModifiedBy,meta]' })
      .then(({ data }) => {
        commit('setRecord', data.data)
        commit('setRecordMeta', data.meta)
        dispatch('getRecordUsers')
      })
  },
  readRecordStatuses: ({ commit }) => records.readRecordStatuses()
    .then(({ data }) => {
      commit('setStatuses', data.data)
    }),
  updateRecordStatus({ commit, dispatch }, { id, status, isLast = true, isSingle, ignoreEventBus = false }) {
    commit('preloaderSaving', true)
    commit('activityStreamLoader', true)
    return records.updateRecordStatus({ id, status, fields: 'quote[id,author[avatar,id,currentSupplier,firstName,lastName],collaborators[firstName,lastName,email,id,avatar],createdDate,description,dueDate,name,status,statusLastChangedAt,updatedDate,recordTypeId,customFields[slug,id,response,hasHistory],lastModifiedBy]' }, ignoreEventBus)
      .then(({ data }) => {
        commit('updateRecordItem', { id, data: data.data, isSingle })
        if (!isLast) {
          return
        }
        dispatch('getRecordActivityStream', id, false)
      })
      .finally(() => {
        commit('preloaderSaving', false)
        commit('activityStreamLoader', false)
      })
  },
  getRecordActivityStream({ commit, state }, id, showLoader = true) {
    if (showLoader) {
      commit('activityStreamLoader', true)
    }
    commit('setActivityStream', [])
    commit('setActivityFirstPage')
    return records.getRecordActivityStream(id, { page: state.activityStreamPage.currentPage })
      .then(({ data }) => {
        const isNeedPostGlobalEvent = data.data.flatMap(({ posts }) => posts).some((post) => {
          return !post.meta.seen
        })
        if (isNeedPostGlobalEvent) {
          eventBus.$emit('activityStreamLoad')
        }
        commit('setActivityStreamPage', data.meta)
        commit('setActivityStream', data.data)
      }).finally(() => {
        commit('activityStreamLoader', false)
      })
  },
  createMessage({ commit, dispatch, rootState }, payload) {
    commit('preloaderSaving', true)
    commit('messageSending', true)
    return records.createMessage(payload, rootState.user.currentUser.email)
      .then(() => {
        dispatch('getRecordActivityStream', payload.id, false)
        dispatch('getRecordFiles', { id: payload.id })
        dispatch('getRecordFileGroups', payload.id)
      }).finally(() => {
        commit('preloaderSaving', false)
        commit('messageSending', false)
      })
  },
  updateMessage({ commit, dispatch }, payload) {
    commit('preloaderSaving', true)
    commit('messageUpdating', true)
    return records.updateMessage(payload)
      .then(() => {
        dispatch('getRecordActivityStream', payload.id, false)
        dispatch('getRecordFiles', { id: payload.id })
        dispatch('getRecordFileGroups', payload.id)
      }).finally(() => {
        commit('preloaderSaving', false)
        commit('messageUpdating', false)
      })
  },
  createIssue({ commit, dispatch }, payload) {
    commit('issueCreating', true)
    return records.createIssue(payload)
      .then(() => {
        dispatch('getRecordActivityStream', payload.id)
      }).finally(() => {
        commit('issueCreating', false)
      })
  },
  confirmRecordFulfillment({ dispatch }, payload) {
    return records.confirmRecordFulfillment(payload)
      .then(() => {
        dispatch('getRecord', payload.orderId)
        dispatch('getRecordActivityStream', payload.orderId)
      })
  },
  getRecordFiles({ commit }, payload) {
    return records.getRecordFiles(payload)
      .then(({ data }) => {
        commit('setRecordFiles', data.data)
        commit('setFilesRelatedUsers', data.meta)
      })
  },
  getRecordFileGroups({ commit }, id) {
    return records.getRecordFileGroups(id)
      .then(({ data }) => {
        commit('setRecordFileGroups', data.data)
      })
  },
  checkIsExist(context, data) {
    return records.isExist(data)
  },
  addCollaborators({ commit }, data) {
    commit('preloaderCollaborators', true)
    return records.addCollaborators(data).then(({data}) => {
      commit('setCollaborators', data.data)
      return data.data
    }).catch((err) => {
      commit('preloaderCollaborators', false)
      throw err
    }).finally(() => {
      commit('preloaderCollaborators', false)
    })
  },
  removeCollaborators({ commit }, data) {
    return records.removeCollaborators(data).then(({data}) => {
      commit('setCollaborators', data.data)
      return data.data
    })
  },
  getFiltersStructure({ commit, state }, isPersist = true) {
    if (Object.keys(state.filtersSettings).length && !isPersist) {
      const clonedData = cloneDeep(state.filtersSettings)
      if (clonedData?.data?.lastRequestQueryLog?.data) {
        clonedData.data.lastRequestQueryLog.data = state.viewFilters
      }
      commit('addUserSettingFilters', clonedData)
      return null
    }
    return records.getFiltersStructure()
      .then(({ data }) => {
        commit('addFilters', data.data.options)
        commit('addAdvancedOptions', data.data.filteringOptions)
        const clonedData = cloneDeep(data)
        if (!isPersist && clonedData?.data?.lastRequestQueryLog?.data) {
          clonedData.data.lastRequestQueryLog.data = state.viewFilters
        }
        commit('addUserSettingFilters', clonedData)
      })
  },
  updateRecordFilter({ commit }, dataFilter) {
    const data = Object.fromEntries(Object.entries(dataFilter).filter(([, field]) => field !== null))
    commit('setFilter', data)
  },
  editObjectChild({ dispatch }, payload) {
    return records.editObjectChild(payload).then(() => {
      dispatch('getRecord', payload.id)
    })
  },
  createObjectChilds({ commit }, payload) {
    commit('preloaderSaving', true)
    return records.createObjectChilds(payload).catch((err) => {
      commit('preloaderSaving', false)
      throw err
    })
      .finally(() => {
        commit('preloaderSaving', false)
      })
  },
  deleteObjectChild({ commit, dispatch, getters }, payload) {
    commit('preloaderSaving', true)
    return records.deleteObjectChild(payload).then((data) => {
      dispatch('getRecordSilent', getters.getFormattedRecord.id)
      return data
    }).catch((err) => {
      commit('preloaderSaving', false)
      throw err
    })
      .finally(() => {
        commit('preloaderSaving', false)
      })
  },
  getObjectChildrenFields(_, payload) {
    return records.getObjectChildren(payload).catch((err) => {
      throw err
    })
  },
  getObjectChildrenMeta(_, payload) {
    return records.getObjectChildrenMeta(payload)
  },
  patchObjectField({ commit }, payload) {
    commit('preloaderSaving', true)
    return records.patchObjectField(payload)
      .catch((err) => {
        commit('preloaderSaving', false)
        throw err
      })
      .finally(() => {
        commit('preloaderSaving', false)
      })
  },
  deleteRecord({ commit }, payload) {
    commit('preloaderSaving', true)
    return records.deleteRecord(payload)
      .catch((err) => {
        commit('preloaderSaving', false)
        throw err
      })
      .finally(() => {
        commit('preloader', true)
        commit('preloaderSaving', false)
      })
  },
  loadPersistentColumns({ commit }) {
    return records.getPersistentColumns().then(({ data }) => {
      commit('setPersistentColumns', data)
    })
  },
  savePersistentColumns(ctx, payload) {
    return records.savePersistentColumns(payload)
  },
  setTemplateDocumentsList({ commit }, payload) {
    return records.templateDocumentsList(payload).then((res) => {
      commit('setTemplateDocumentsList', res.data.data)
    }).catch((err) => {
      throw err
    })
  },
  deleteFileFromRecord(context, payload) {
    return records.deleteFileFromRecord(payload).then(() => {
      context.commit('deleteRecordFile', payload.fileId)
    })
  },
  getRecordCustomFieldsConfiguration: ({ commit }) => {
    return Promise.all([
      records.loadRecordCustomFieldsConfiguration(),
      records.loadRecordSections(),
    ]).then(([customFieldsConfiguration, sections]) => {
      commit('setCustomFieldsConfiguration', customFieldsConfiguration.data.data)
      commit('setSections', sections.data.data)
    })
  },
  loadFilterViews: ({ commit }) => {
    commit('setFilterViewsLoaded', false)
    return records.loadFiltersViews().then(({ data: { data } }) => {
      commit('setFilterViews', data.map((view) => {
        return {
          ...view,
          count: null,
          isCountLoading: false,
        }
      }))
      commit('setFilterViewsLoaded', true)
    })
  },
  async fetchActivityStream({ commit, state }, id, showLoader = true) {
    if (state.activityStream.length >= state.activityStreamPage.total || state.isActivityStreamLoading) {
      return null
    }
    if (showLoader) {
      commit('activityStreamLoader', true)
    }
    return records.getRecordActivityStream(id, {
      page: state.activityStreamPage.currentPage + 1,
    })
      .then(({ data }) => {
        const isNeedPostGlobalEvent = data.data.flatMap(({ posts }) => posts).some((post) => {
          return !post.meta.seen
        })
        if (isNeedPostGlobalEvent) {
          eventBus.$emit('activityStreamLoad')
        }
        commit('setActivityStream', [...state.activityStream, ...data.data])
        commit('setActivityStreamPage', data.meta)
        return data
      }).finally(() => {
        commit('activityStreamLoader', false)
      })
  },
  getCollaborators({commit}, payload) {
    return records.getRecordCollaborators(payload)
      .then(({ data }) => {
        commit('setCollaborators', data.data)
      }).catch((err) => {
        throw err
      })

  },
  requestTemplateSignature({ commit }, payload) {
    commit('preloader', true)
    return records.signTemplate(payload)
      .catch((err) => {
        commit('preloader', false)
        throw err
      }).finally(() => {
        commit('preloader', false)
      })
  },
}
