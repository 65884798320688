import store from '@/store'

const tenantAdmin = async ({ to, next, from }) => {
  const isAuth = localStorage.getItem('token')

  if (!isAuth) {
    sessionStorage.setItem('userRequestUrl', JSON.stringify({ name: to.name, query: to.query, params: to.params }))
    return next({ name: 'login' })
  }

  if (!store.state.user.currentUser) {
    await store.dispatch('user/getCurrentUserAccount')
  }

  if (store.state.user.currentUser?.administratedTenants?.includes(to.params.tenant)) {
    return next()
  }

  return next(from)
}

export default tenantAdmin
