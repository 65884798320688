export default {
  preOrderOptions: ({ preOrders }) => preOrders.map(({ name, id }) => ({ label: name, value: id })),
  companiesRecord: ({ companies }) => companies.reduce((acc, company) => {
    acc[company.id] = company
    return acc
  }, {}),
  canSwitch: ({ companies }) => companies.length > 1,
  getCurrentCompanyPrivateData: ({ companies, currentCompany }) => {
    return companies.find((company) => company.slug === currentCompany?.slug)
  },
}
