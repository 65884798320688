import httpClient from "@/httpClient"

const getFileGroups = () => httpClient.get('{currentCompanySlug}/files/groups')

const createFileGroups = (payload) => httpClient.post('{currentCompanySlug}/files/groups', payload)

const updateFileGroups = ({ groupId, payload }) => httpClient.put(
  `{currentCompanySlug}/files/groups/${groupId}`,
  payload,
  {
    headers: {
      notification: 'silent',
    },
  },
)

const reorderFileGroups = (payload) => httpClient.post('{currentCompanySlug}/files/groups/reorder', payload)

const getFileGroup = ({ groupId }) => httpClient.get(`{currentCompanySlug}/files/groups/${groupId}`)

const deleteFileGroup = ({ groupId, replacementFileGroupId }) => httpClient({
  url: `{currentCompanySlug}/files/groups/${groupId}`,
  method: 'DELETE',
  data: { replacementFileGroupId },
  ignoreGlobalNotify: true,
})

const removeFile = ({
  entity,
  orderId,
  fileId,
}) => httpClient.delete(`{currentCompanySlug}/${entity}/${orderId}/file-attachment/${fileId}`)

const updateFile = ({
  entity,
  fileId,
  orderId,
  ...payload
}) => httpClient.patch(
  `{currentCompanySlug}/${entity}/${orderId}/file-attachment/${fileId}`,
  { ...payload },
)

const signFile = ({
  fileId,
  orderId,
  payload,
}) => httpClient.post(
  `{currentCompanySlug}/quotes/${orderId}/file-attachment/${fileId}/e-sign/freeForm`,
  payload,
)

const downloadFile = ({ endpoint }) => httpClient({
  url: endpoint,
  method: 'GET',
})

const previewFile = ({ endpoint }) => httpClient({
  url: endpoint,
  method: 'GET',
})

const parseFile = ({ endpoint }) => httpClient({
  url: endpoint,
  method: 'POST',
})

const generateFileSummary = ({
  orderId,
  fileId,
}) => httpClient.post(`{currentCompanySlug}/quotes/${orderId}/file-attachment/${fileId}/summary`)

const getLatestSummary = ({ orderId, fileId }) =>
  httpClient.get(`{currentCompanySlug}/quotes/${orderId}/file-attachment/${fileId}/summary`, {
    ignoreGlobalNotFoundCatch: true,
    ignoreGlobalNotify: true,
  })

export default {
  getFileGroups,
  createFileGroups,
  downloadFile,
  removeFile,
  updateFile,
  updateFileGroups,
  getFileGroup,
  deleteFileGroup,
  reorderFileGroups,
  signFile,
  parseFile,
  previewFile,
  generateFileSummary,
  getLatestSummary,
}
