import store from '@/store'

const internalAdmin = async ({ to, next, from }) => {
  const isAuth = localStorage.getItem('token')

  if (!isAuth) {
    sessionStorage.setItem('userRequestUrl', JSON.stringify({ name: to.name, query: to.query, params: to.params }))
    const company = to?.params?.company
    if (company) {
      return next({ name: 'company-login', params: { company } })
    }
    return next({ name: 'login' })
  }
  if (!store.state.user.currentUser) {
    await store.dispatch('user/getCurrentUser', to.params.company)
  }
  if (store.getters['user/isAdmin']) {
    return next()
  }
  return next(from)
}

export default internalAdmin
